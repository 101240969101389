export const BASE_URL: string = `https://api.${process.env.REACT_APP_ENVIRONMENT}.aws.jit.sfdc.sh`;
export const ACCOUNT_VENDING_BASE_URL: string = `https://accountvendor.${process.env.REACT_APP_ENVIRONMENT}.aws.jit.sfdc.sh`;

export const HandleErrorResponse = async (response: any) => {
    let errorMessage;
    try {
        const errorBody = await response.json();
        errorMessage = errorBody.message || 'Error message not available';
    } catch (jsonError) {
        errorMessage = 'Failed to parse error message from response';
    }

    if (response.status === 401) {
        throw new Error(`TokenExpired`);
    }
    throw new Error(errorMessage);
}