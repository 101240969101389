import React from "react";
import {DataTableCell} from "@salesforce/design-system-react";
import {AccountClosureRow} from "../../types/accountClosure";

export type CustomStatusCellProps = {
    children?: React.ReactNode;
    item?: AccountClosureRow;
};
const CustomAutoBreakDataTableCell: React.FC<CustomStatusCellProps> = ({ children, ...props }) => {
    const breakTextIntoChunks = (text: string, chunkSize: number) => {
        const regex = new RegExp(`.{1,${chunkSize}}`, "g");
        return text.match(regex);
    };

    const textChunks = breakTextIntoChunks(children as string, 25);

    return (
        <td className="slds-text-align_left slds-p-around_medium">
            <p>
                {textChunks && textChunks.map((chunk, index) => (
                <React.Fragment key={index}>
                    {chunk}
                    {index < textChunks.length - 1 && <br/>}
                    </React.Fragment>
                ))}
            </p>
        </td>
    );
};
CustomAutoBreakDataTableCell.displayName = DataTableCell.displayName;
export default CustomAutoBreakDataTableCell;
