import {Component} from "react";
import {Button, Input, Modal, Textarea} from "@salesforce/design-system-react";
import React from "react";
import {ToastState} from "../utils/types/toast";
import {CONSTANT_ACCESS_REQUEST} from "../constants/accessRequest";
import {TOAST_MESSAGE} from "../constants/toastMessages";

type CredentialsProps = {
    isOpen: boolean;
    toggleModal: (item: any) => void;
    fetchedCredentials?: {
        AccessKeyID: string;
        SecretAccessKey: string;
        SessionToken: string;
        LoginURL: string;
    };
    requestDetails?: any;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type CredentialsState = {
    isCredentialsVisible: boolean;
};
class Credentials extends Component<CredentialsProps, CredentialsState> {
    state: CredentialsState = {
        isCredentialsVisible: false
    }

    toggleCredentialsVisibility = () => {
        this.setState(prevState => ({
            isCredentialsVisible: !prevState.isCredentialsVisible
        }));
    }

    copyCredentials = async () => {
        let parts = [];

        parts.push(`AWS_ACCESS_KEY_ID=${this.props.fetchedCredentials?.AccessKeyID}`)
        parts.push(`AWS_SECRET_ACCESS_KEY=${this.props.fetchedCredentials?.SecretAccessKey}`)
        parts.push(`AWS_SESSION_TOKEN=${this.props.fetchedCredentials?.SessionToken}`)
        const creds = ` export ${parts.join(" ")}`
        try {
            await navigator.clipboard.writeText(creds);
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.COPY_CREDENTIALS_DETAILS,
                    heading: TOAST_MESSAGE.SHARE_ACCESS,
                },
                variant: "success"
            });
            console.log('Text copied to clipboard');
        } catch (err) {
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.COPY_CREDENTIALS_ERROR,
                    heading: TOAST_MESSAGE.ERROR,
                },
                variant: "error"
            });
            console.error('Failed to copy text: ', err);
        }
    }

    render() {
        const { AccessKeyID = "", SecretAccessKey = "", SessionToken = "" } = this.props.fetchedCredentials || {};
        const masked = "••••••••••••••••••••••••";
        // GCP Access request
        if (AccessKeyID === "") {
            return (
                <Modal
                    isOpen={this.props.isOpen}
                    size="medium"
                    footer={[
                        <a className="slds-p-right--small" href={this.props.fetchedCredentials?.LoginURL} target="_blank" rel="noopener noreferrer">
                            <Button label={CONSTANT_ACCESS_REQUEST.LAUNCH_CONSOLE} variant="brand"/>
                        </a>,
                    ]}
                    onRequestClose={this.props.toggleModal}
                    heading={CONSTANT_ACCESS_REQUEST.CREDENTIALS}
                >
                    <section className="slds-has-divider--bottom">
                        <div className="slds-p-top--small slds-p-left--large slds-p-bottom--small">
                            {CONSTANT_ACCESS_REQUEST.ACCOUNT} : {this.props.requestDetails?.item?.aws_account_display}
                        </div>
                        <div className="slds-p-left--large slds-p-bottom--small">
                            {CONSTANT_ACCESS_REQUEST.ROLE} : {this.props.requestDetails?.item?.role_name}
                        </div>
                        <div className="slds-p-left--large slds-p-bottom--medium">
                            {CONSTANT_ACCESS_REQUEST.EXPIRATION} : {this.props.requestDetails?.item?.expires_at}
                        </div>
                    </section>
                    <section className="slds-p-around_large">
                        <p>PCSK has already updated IAM policies for the given user in GCP project. </p>
                        <p>When finished, please revoke the access request by clicking on Revoke button.</p>
                        <br/>
                        <p>For CLI access:</p>
                        <p>1. Please login to the google cli using <b> gcloud auth login </b> </p>
                        <p>2. Update gcloud config for the project using <b> gcloud config set project &lt;ProjectID&gt; </b> </p>
                    </section>
                </Modal>
            )
        }

        // AWS Access request
        return (
            <Modal
                isOpen={this.props.isOpen}
                size="medium"
                footer={[
                    <a className="slds-p-right--small" href={this.props.fetchedCredentials?.LoginURL} target="_blank" rel="noopener noreferrer">
                        <Button label={CONSTANT_ACCESS_REQUEST.LAUNCH_CONSOLE} variant="brand"/>
                    </a>,
                    <Button key='copyCredentials' label={CONSTANT_ACCESS_REQUEST.COPY_CREDENTIALS} onClick={this.copyCredentials} />,
                    <Button key='showCredentials' label={this.state.isCredentialsVisible ? CONSTANT_ACCESS_REQUEST.HIDE_CREDENTIALS : CONSTANT_ACCESS_REQUEST.SHOW_CREDENTIALS} onClick={this.toggleCredentialsVisibility} />,
                ]}
                onRequestClose={this.props.toggleModal}
                heading={CONSTANT_ACCESS_REQUEST.CREDENTIALS}
            >
                <section className="slds-has-divider--bottom">
                    <div className="slds-p-top--small slds-p-left--large slds-p-bottom--small">
                        {CONSTANT_ACCESS_REQUEST.ACCOUNT} : {this.props.requestDetails?.item?.aws_account_display}
                    </div>
                    <div className="slds-p-left--large slds-p-bottom--small">
                        {CONSTANT_ACCESS_REQUEST.ROLE} : {this.props.requestDetails?.item?.role_name}
                    </div>
                    <div className="slds-p-left--large slds-p-bottom--medium">
                        {CONSTANT_ACCESS_REQUEST.EXPIRATION} : {this.props.requestDetails?.item?.expires_at}
                    </div>
                </section>
                <section className="slds-p-around_large">
                    <Input
                        id="access_key"
                        type="text"
                        label={CONSTANT_ACCESS_REQUEST.AWS_ACCESS_KEY_ID}
                        value={AccessKeyID}
                        disabled
                    />
                    <Input
                        id="access_secret"
                        type="text"
                        label={CONSTANT_ACCESS_REQUEST.AWS_SECRET_ACCESS_KEY}
                        value={this.state.isCredentialsVisible ? SecretAccessKey : masked}
                        disabled
                    />
                    <Textarea
                        id="access_session"
                        type="text"
                        label={CONSTANT_ACCESS_REQUEST.AWS_SESSION_TOKEN}
                        value={this.state.isCredentialsVisible ? SessionToken : masked}
                        disabled
                    />
                </section>
            </Modal>
        );
    }
}

export default Credentials