import React, { useEffect, useState } from "react";
import { DataTableColumn, DataTable, Button, DataTableCell } from "@salesforce/design-system-react";
import {approveAccountClosure, getAccountClosures} from "../../../services/accountClosure";
import { ToastState } from "../../../utils/types/toast";
import { AccountClosureRow } from "../../../utils/types/accountClosure";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import CustomAutoBreakDataTableCell, {CustomStatusCellProps} from "../../../utils/components/table_cell/auto_break_cell"
import {CustomHeightPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay"
import AccountClosureApprovalModal from "../../../modals/AccountClosureApprovalModal"

type AccountClosureProps = {
    action?: (type: string) => void;
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

const AccountClosure: React.FC<AccountClosureProps> = ({isActive, toast, setToast}) => {
    // stateful on page
    const [data, setData] = useState<AccountClosureRow[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedRow, setSelectedRow] = useState<AccountClosureRow | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isAccountListLoading, setIsAccountListLoading] = useState<boolean>(false);
    const [isApprovalInProgress, setIsApprovalInProgress] = useState<boolean>(false);

    // pagination controls
    const rowsPerPage: number = 10;
    const totalPages: number = Math.ceil(data.length / rowsPerPage);
    const startIndex: number = (currentPage - 1) * rowsPerPage;
    const endIndex: number = Math.min(currentPage * rowsPerPage, data.length);
    const currentPageData: AccountClosureRow[] = data.slice(startIndex, endIndex);

    // Handle previous page button click
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    // Handle next page button click
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    // Function to handle opening modal and setting selected row data
    const handleApproveButtonClick = (item: AccountClosureRow | undefined) => {
        if (item) {
            setSelectedRow(item);
            setIsModalOpen(true);
        } else {
            console.error("Item is undefined.");
        }
    };

    // handle confirmed approval
    const handleConfirmApproval = () => {
        setIsApprovalInProgress(true);
        if (selectedRow == null) {
            return
        }
        let accID = selectedRow?.aws_account_id
        approveAccountClosure(accID).then((response) => {
            let accID = ""
            if (selectedRow?.aws_account_id !== undefined) {
                accID = selectedRow?.aws_account_id
            }
            toast.isVisible = false
            setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCOUNT_CLOSURE_APPROVAL_SUCCESS(accID),
                    heading: "",
                },
                variant: "success"
            });
            setData(prevData => {
                return prevData.map((item, index) => {
                    if (item.aws_account_id === accID) {
                        return {
                            ...item,
                            approved_by: `${response.approved_by}`,
                            status: response.status,
                            can_approve: response.can_approve,
                        };
                    }
                    return item
                });
            });
        }).catch((message) => {
            toast.isVisible = false
            setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCOUNT_CLOSURE_APPROVAL_FAILED(accID, message),
                    heading: "",
                },
                variant: "error"
            });
        }).finally(() => {
            setIsApprovalInProgress(false)
            setIsModalOpen(false);
        });
    };

    // Custom cell that displays the action buttons
    const CustomClosureActionsCell: React.FC<CustomStatusCellProps> = ({ children, item, ...props }) => {
        if (!item?.can_approve) {
            return (
                <td className='slds-text-align_left'>
                    None
                </td>
            );
        }
        return (
            <td className='slds-text-align_left'>
                <Button
                    label="Approve"
                    className='slds-button slds-button_brand slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'
                    onClick={() => handleApproveButtonClick(item)}
                />
            </td>
        );
    };
    CustomClosureActionsCell.displayName = DataTableCell.displayName;


    // Handle page re-loads and tab switches
    useEffect(() => {
        if (isActive) {
            setIsAccountListLoading(true)
            setIsModalOpen(false); // close modal on fresh account reloads
            getAccountClosures().then((response: any[]) => {
                if (Array.isArray(response)) {
                    const constructedData: AccountClosureRow[] = response.map((item, index) => ({
                        index: `${index + 1}`,
                        guid: item.id,
                        approved_by: `${item.approved_by}`,
                        name: item.name,
                        aws_account_id: item.aws_account_id,
                        email: item.email,
                        org_account_id: item.org_account_id,
                        requested_at: item.requested_at,
                        expiry: item.expiry,
                        status: item.status,
                        can_approve: item.can_approve
                    }));
                    setData(constructedData);
                } else {
                    // API responds null in-case there are no accounts
                    setData([]);
                }
            }).catch((message) => {
                setToast({
                    isVisible: true,
                    message: {
                        details: TOAST_MESSAGE.FAILED_LOADING_ACCOUNT_CLOSURES(message),
                        heading: "",
                    },
                    variant: "error"
                });
            }).finally(() => {
                setIsAccountListLoading(false)
                setCurrentPage(1)
            });
        }
    }, [isActive]);

    return (
        <div className="slds-m-bottom_xx-large slds-is-relative slds-size_1-of-1" style={{minHeight: "80vh"}}>
            {/* Closure Instructions */}
            <div className="slds-col slds-size_8-of-8">
                <div className="slds-box slds-theme_shade">
                    <strong>Instructions:</strong>
                    <ul className="slds-list_dotted">
                        <li>
                            The table below presents your accounts currently in the PCSK queue awaiting closure.
                            if your account closure request has expired then the account will not be listed.
                        </li>
                        <li>
                            To confirm the closures, please click on the 'Approve' button. Once approved, no
                            further action is required from your side.
                        </li>
                        <li>
                            The closure process awaits approvals until it reaches the specified 'Approval
                            Expiry' deadline, as outlined in the table.
                            If the necessary closure approvals are not obtained by that date, the account will
                            be removed from the queue.
                            A fresh new request must be submitted to push it again for closure.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="slds-col slds-size--8-of-8">
            </div>
            <>
                {isAccountListLoading ? (
                    <CustomHeightPageSpinner size="medium" minHeight="50vh"/>
                ) : data.length > 0 ? (
                    <>
                        {/* Pagination Info */}
                        <div className="slds-grid slds-grid_vertical-align-center slds-m-bottom_small slds-m-top_small slds-m-right_medium">
                        <span className="slds-col">
                            <b>Showing {startIndex + 1} - {endIndex} of {data.length}</b>
                        </span>
                        <div className={totalPages <= 1 ? 'slds-hidden' : 'slds-visible'}>
                            <Button label="<" variant={currentPage === 1 ? 'neutral' : 'brand'} onClick={handlePreviousPage} disabled={currentPage === 1} className="slds-m-right_xx-small" />
                            Page {currentPage} of {totalPages}
                            <Button label=">" variant={currentPage === totalPages ? 'neutral' : 'brand'} onClick={handleNextPage} disabled={currentPage === totalPages} className="slds-m-right_xx-small" />
                        </div>
                        </div>
                        {/* Table */}
                        <div className="slds-col slds-size_8-of-8 slds-wrap slds-m-top_small" style={{ width: '99%' }}>
                            <DataTable
                                items={currentPageData}
                                id="account-closures-table"
                                hasCellActions={false}
                                striped={true}
                                columnBordered={true}
                                resizeBehavior="auto"
                            >
                                <DataTableColumn label="Index" property='index'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Name" property='name'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="AWS Account ID" property='aws_account_id'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Email" property='email'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Org Account ID" property='org_account_id'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Requested At" property='requested_at'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Approval Expiry" property='expiry'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Approved By" property='approved_by'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Status" property='status'>
                                    <CustomAutoBreakDataTableCell/>
                                </DataTableColumn>
                                <DataTableColumn label="Action" property='can_approve'>
                                    <CustomClosureActionsCell/>
                                </DataTableColumn>
                            </DataTable>
                        </div>
                    </>
                ): (
                    <>
                        <div className="slds-col slds-size_8-of-8 slds-m-top_medium">
                            <strong>No results</strong>
                        </div>
                    </>
                )}

            </>
            {/* Approval Modal */}
            <AccountClosureApprovalModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isApprovalInProgress={isApprovalInProgress}
                handleConfirmApproval={handleConfirmApproval}
                awsAccountID={selectedRow == null ? "" : selectedRow?.aws_account_id}
                awsAccountName={selectedRow == null ? "" : selectedRow?.name}
                awsAccountEmail={selectedRow == null ? "" : selectedRow?.email}
            />
        </div>
    );
};
export default AccountClosure;
