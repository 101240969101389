import {BASE_URL, HandleErrorResponse} from "./common";
export const getAccountClosures = async () => {
    const endpoint = `${BASE_URL}/account-closures?filter%5Bstatus%5D=user_owned`;
    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();

    } catch (error) {
        console.error("Error fetching active account closures:", error);
        throw error;
    }
}

export const approveAccountClosure = async (aws_account_id: string) => {
    const endpoint = `${BASE_URL}/account-closures/approve`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify({ "aws_account_id": aws_account_id })
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error approving closure request:", error);
        throw error;
    }
}
